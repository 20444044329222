.page {
  margin-top: 20px;
}

.left-navigation {
  .heading {
    padding-left: 5px;
    padding-bottom: 5px;
    font-weight: bold;
  }

  .menu a, .menu button {
    padding: 0.3rem 1rem;
  }
  & > ul > li {
    padding-bottom: 20px;
  }
}